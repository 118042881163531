import { onError } from '@apollo/client/link/error';
import { getOperationUuid, getSessionUuid } from '../lib/utils/logCorrelation';


const getLocalStorageId = () => `X-Session-ID: ${getSessionUuid()} Correlation-ID: ${getOperationUuid()}`;

const ErrorCodeLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      const localStorageId = getLocalStorageId();
      const finalMessage = `${message} - ${localStorageId}`;
      // eslint-disable-next-line no-console
      console.error(finalMessage);
    });
  }

  if (networkError) {
    const localStorageId = getLocalStorageId();
    const finalMessage = `Network error: ${networkError.message} - ID: ${localStorageId}`;
    // eslint-disable-next-line no-console
    console.error(finalMessage);
  }
});


export default ErrorCodeLink;
