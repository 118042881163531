import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { addField, FieldTitle } from 'ra-core';
import PropTypes from 'prop-types';

import {
  InputLabel, FormControl, withStyles, Select,
  MenuItem, Checkbox, ListItemText, makeStyles,
  Chip, Typography, Box,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { NO_FOUND_INDEX } from './constants';

const SELECT_ALL = { id: 'all', name: 'Select All' };

const styles = {
  label: {
    position: 'relative',
  },
};

const useStyles = makeStyles(theme => ({
  multiSelect: {
    width: 'inherit',
    flexWrap: 'wrap',
    paddingTop: 15,
  },
  chip: {
    margin: 2,
    pointerEvents: '',

  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorField: {
    fontSize: 12,
    color: theme.palette.error.main,
    marginTop: 2,
  },
  container: {
    marginBottom: 20,
    width: '100%',
  },
}));

const MultiSelectRetailerComp = (props) => {
  const {
    meta: { touched, error },
    label,
    id,
    source,
    resource,
    isRequired,
    retailers,
    retailersList,
    className,
  } = props;
  const [values, setValues] = useState(retailers);
  const [selectAll, setSelectAll] = useState(retailers.length
    && retailers.length === retailersList.length);
  const classes = useStyles();

  const handleValueChange = (val) => {
    const { input: { onChange }, onChangeValue } = props;
    const selectedValues = val.map(retailer => retailer.id);
    onChange(selectedValues);
    onChangeValue(val);
  };

  const handleDelete = (retailer) => {
    const selectedValues = values.filter(item => item.id !== retailer.id);
    setValues(selectedValues);
    handleValueChange(selectedValues);
  };

  const renderValue = selected => (
    <div className={classes.chips}>
      {selected.map(retailer => (
        <Chip
          key={retailer}
          label={retailer.name}
          clickable
          className={classes.chip}
          onDelete={() => handleDelete(retailer)}
          deleteIcon={(
            <CancelIcon
              onMouseDown={event => event.stopPropagation()}
            />
          )}
        />
      ))}
    </div>
  );

  const isChecked = retailerId => values?.map(item => item.id)
    ?.indexOf(retailerId) > NO_FOUND_INDEX;

  const onSelectItem = (retailer) => {
    let selectedValues = [...values];
    if (values.filter(item => item.id === retailer.id).length) {
      selectedValues = selectedValues.filter(item => item.id !== retailer.id);
    } else {
      selectedValues.push(retailer);
    }
    setSelectAll(false);
    setValues(selectedValues);
    handleValueChange(selectedValues);
  };

  const onSelectAll = () => {
    if (!selectAll) {
      setSelectAll(true);
      setValues(retailersList);
      handleValueChange(retailersList);
    } else {
      setSelectAll(false);
      setValues([]);
      handleValueChange([]);
    }
  };

  return (
    <FormControl
      error={!!(touched && error)}
      className={className}
    >
      {label !== '' && label !== false && (
        <InputLabel shrink htmlFor={id} className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </InputLabel>
      )}
      <Box className={classes.container}>
        <Select
          id="grouped-select"
          multiple
          value={values}
          className={classes.multiSelect}
          renderValue={renderValue}
        >
          <MenuItem value={SELECT_ALL.id} key={SELECT_ALL.id} onClick={onSelectAll}>
            <Checkbox checked={selectAll} />
            <ListItemText primary={SELECT_ALL.name} />
          </MenuItem>
          {
            retailersList && retailersList.map(retailer => (
              <MenuItem
                value={retailer}
                key={retailer.id}
                onClick={() => onSelectItem(retailer)}
              >
                <Checkbox checked={isChecked(retailer.id)} />
                <ListItemText primary={retailer.name} />
              </MenuItem>
            ))
          }
        </Select>
        {touched && error && (
          <Typography className={classes.errorField}>
            {error}
          </Typography>
        )}
      </Box>
    </FormControl>
  );
};

const MultiSelectRetailer = addField(withStyles(styles)(MultiSelectRetailerComp));

MultiSelectRetailer.propTypes = {
  input: PropTypes.object,
  source: PropTypes.string,
};

MultiSelectRetailer.defaultProps = {
  addLabel: false,
  fullWidth: true,
};
export default MultiSelectRetailer;
