import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';

const SESSION_UUID = `session-uuid_${process.env.REACT_APP_ID}`;
const OP_UUID = `op-uuid_${process.env.REACT_APP_ID}`;

const getSessionUuid = () => sessionStorage.getItem(SESSION_UUID);
const setSessionUuid = value => sessionStorage.setItem(SESSION_UUID, value);
const setOperationUuid = value => sessionStorage.setItem(OP_UUID, value);
const getOperationUuid = () => sessionStorage.getItem(SESSION_UUID);

const getSessionKey = () => {
  if (getSessionUuid()) {
    return getSessionUuid();
  }

  const key = uuidv4();
  setSessionUuid(key);

  return key;
};
export {
  getSessionKey, setOperationUuid, getOperationUuid, getSessionUuid, setSessionUuid,
};
